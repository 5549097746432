import {MenuType} from "@/composables/menu/enums";
import {HardKitType} from "pg-isomorphic/enums/element";
import type {RouteRecordRaw} from "vue-router";
import {createRouter, createWebHistory} from "vue-router";
import adminRoutes from "./adminRoutes";
import {makeResponderSignInRoute, responderReportsRoute, responderRoute, responderSignUpRoute} from "./responderRoutes";
import testRoutes from "./testRoutes";

const signInRoute = {
  path: "/signin",
  component: () => import("@/components/auth/SignIn.vue"),
  meta: {
    requiresAuth: false,
    title: "nav.sign_in",
    menu_type: MenuType.Setup,
  },
  props: (route) => ({
    newEmail: route.query.email,
    invited: route.query.invited,
    invitedContact: route.query.invitedContact,
    invite: route.query.invite,
    campaign: route.query.campaign,
  }),
};

const responderSignInRoute = makeResponderSignInRoute(signInRoute);

export function makeGraphiteRouter() {
  return createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if ((from && Object.keys(to.query).length) || to.fullPath.split("?")[0] === from.fullPath.split("?")[0]) {
        return null;
      }
      if (savedPosition) {
        return savedPosition;
      } else {
        return {top: 0};
      }
    },
    routes: [
      {
        path: "/",
        component: () => import("@/components/auth/LandingPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/signout",
        component: () => import("@/components/auth/SignOut.vue"),
        meta: {
          requiresAuth: false,
          menu_type: MenuType.Setup,
          title: "nav.signout",
        },
        props: (route) => ({
          signoutFrom: route.query.signoutFrom,
        }),
      },
      {
        path: "/as",
        component: () => import("@/components/layouts/public.vue"),
        children: [
          {
            path: "/auth",
            component: () => import("@/components/layouts/AuthWrap.vue"),
            children: [
              signInRoute,
              responderSignInRoute,
              responderSignUpRoute,
              {
                path: "/forgot/:startingEmail?",
                component: () => import("@/components/auth/ForgotEmail.vue"),
                meta: {
                  menu_type: MenuType.Setup,
                  requiresAuth: false,
                  title: "nav.forgot",
                },
              },
              {
                path: "/password_reset/:startingEmail?",
                component: () => import("@/components/auth/ForgotResetLanding.vue"),
                meta: {
                  menu_type: MenuType.Setup,
                  requiresAuth: false,
                  title: "nav.forgot",
                },
              },
              {
                path: "/signup/:step?/:campaign?",
                component: () => import("@/components/auth/SignUp.vue"),
                meta: {
                  menu_type: MenuType.Setup,
                  requiresAuth: false,
                  no_role_modal: true,
                  title: "nav.sign_up",
                },
                props: (route) => ({
                  entityName: route.query.entityName,
                  sender: route.query.senderName,
                  initialToken: route.query.inviteToken,
                  newName: route.query.newName,
                  shareToken: route.query.shareToken,
                  entityId: route.query.entity,
                  contactToken: route.query.token,
                  newEmail: route.query.email,
                  name: route.query.name,
                  step: route.params.step,
                  campaign: route.params.campaign || route.query.campaign,
                  confirmToken: route.query.token,
                  invite: route.query.invite,
                  newContact: route.query.additionalContactInvite,
                  existing: route.query.existingUser,
                }),
              },
              {
                path: "/invalid_invitation",
                component: () => import("@/components/auth/InvalidInvitation.vue"),
                meta: {
                  menu_type: MenuType.Setup,
                  requiresAuth: false,
                  title: "",
                },
                props: (route) => ({
                  from: route.query.f,
                  isCampaign: route.query.c,
                  invitingEntityName: route.query.i,
                }),
              },
              {
                path: "/awaiting-access",
                component: () => import("@/components/auth/AwaitingAccess.vue"),
                meta: {
                  menu_type: MenuType.Normal,
                  requiresAuth: false,
                  title: "",
                },
              },
              {
                path: "/prove-done",
                component: () => import("@/components/auth/ProveDone.vue"),
                meta: {
                  menu_type: MenuType.Setup,
                  requiresAuth: false,
                  title: "",
                },
                props: (route) => ({
                  from: route.query.f,
                  isCampaign: route.query.c,
                  invitingEntityName: route.query.i,
                }),
              },
            ],
          },
          {
            path: "/signup_confirmed",
            redirect: (to) => {
              const {query} = to;
              return {
                path: "/signup/confirmed",
                query: {
                  ...query,
                },
              };
            },
          },
        ],
      },
      {
        path: "/as",
        component: () => import("@/components/layouts/private.vue"),
        children: [
          {
            path: "/dash",
            component: () => import("@/components/home/Home.vue"),
            meta: {
              title: "nav.home",
            },
          },
          {
            path: "/tasks",
            component: () => import("@/components/kits/Kits.vue"),
            meta: {
              title: "nav.tasks",
              kitKey: "tasks",
              fullWidth: true,
            },
            props: () => ({
              kitTypeProp: HardKitType.TASK,
              fullPage: true,
              showSidebar: false,
              maxFilterFieldsCount: 7,
            }),
          },
          {
            path: "/dash-buy",
            redirect: (to) => {
              return {path: "/dash"};
            },
          },
          {
            path: "/buy-search",
            redirect: (to) => {
              const {query} = to;
              return {
                path: "/connections",
                query: {
                  ...query,
                },
              };
            },
          },
          {
            path: "/user/profile",
            component: () => import("@/components/user/UserProfile.vue"),
            meta: {
              title: "nav.profile",
              no_role_modal: true,
              allow_data_share: true,
            },
          },
          {
            path: "/user/awaiting-access",
            component: () => import("@/components/auth/AwaitingAccess.vue"),
            meta: {
              title: "nav.profile",
              no_role_modal: true,
            },
          },
          {
            path: "/user/commprefs",
            component: () => import("@/components/user/CommunicationPrefs.vue"),
            meta: {
              title: "nav.communication_preferences",
              no_role_modal: true,
            },
          },
          {
            path: "/exports",
            component: () => import("@/components/user/Exports.vue"),
            meta: {
              title: "exports.title_center",
              no_role_modal: true,
            },
          },
          {
            // this is used in calculating `getRouterViewKey()`; so do not change it lightly
            name: "search-results",
            path: "/connections/:type?",
            component: () => import("@/components/search/BuySearch.vue"),
            meta: {
              title: "nav.connections",
              fullWidth: true,
            },
          },
          {
            path: "/kit/:kitType",
            component: () => import("@/components/kits/Kits.vue"),
            meta: {
              kitKey: "kits",
            },
          },
          {
            path: "/kit/:kitType/:kitId",
            component: () => import("@/components/kits/KitDetail.vue"),
            props: (route) => ({
              kitType: route.params.kitType,
            }),
            meta: {
              kitKey: "kit-detail",
            },
          },
          {
            path: "/actionplans",
            component: () => import("@/components/actionplans/ActionPlans.vue"),
            meta: {
              title: "nav.action_plans",
            },
          },
          {
            path: "/opportunities/:campaignId?",
            component: () => import("@/components/campaigns/Campaigns.vue"),
            meta: {
              title: "nav.campaigns",
              fullWidth: true,
            },
            props: (route) => ({
              campaignId: route.params.campaignId,
            }),
          },
          {
            path: "/communication",
            component: () => import("@/components/communication/CommunicationCenter.vue"),
            meta: {
              title: "nav.communication_center",
            },
            props: () => ({
              isGlobalCommunicationCenterContext: true,
            }),
          },
          {
            path: "/profile",
            name: "profile",
            component: () => import("@/components/company/ProfileWrap.vue"),
            meta: {
              title: "nav.my_company",
              allow_data_share: true,
            },
          },
          {
            path: "/connection/:graphiteIdOrKey/:answer?",
            name: "connection-lookup",
            component: () => import("@/components/auth/Goto.vue"),
          },
          {
            path: "/goto/:graphiteIdOrKey?/:answer?",
            name: "goto-lookup",
            component: () => import("@/components/auth/Goto.vue"),
          },
          {
            path: "/connection/:entityId/:connectionId/:whichProfile",
            name: "connection",
            component: () => import("@/components/company/ProfileWrap.vue"),
            meta: {
              title: "nav.connection",
              second_menu: "connection-task-tray",
              second_menu_height: 77,
            },
          },
          {
            path: "/connection-basic/:entityId/:connectionId/:whichProfile",
            name: "connection-basic",
            component: () => import("@/components/company/BasicProfile.vue"),
            meta: {
              title: "nav.connection",
              hide_menu: true,
              auto_2fa: true,
            },
            props: (route) => ({
              whichProfile: route.params.whichProfile,
              connectionId: route.params.connectionId,
              connectionRole: route.query.role,
              print: route.query.print,
              elvt: route.query.elvt,
            }),
          },
          {
            path: "/search/results",
            redirect: (to) => {
              const {query} = to;
              return {
                path: "/connections",
                query: {
                  ...query,
                },
              };
            },
          },
          {
            path: "/data_share",
            name: "data_share",
            component: () => import("@/components/company/DataShareLanding.vue"),
            meta: {
              requiresAuth: false,
              title: "nav.data_share",
              menu_type: MenuType.DataShare,
              allow_data_share: true,
            },
            props: (route) => ({
              token: route.query.token,
            }),
          },
          {
            path: "/error",
            name: "error",
            component: () => import("@/components/error/Error.vue"),
            meta: {
              requiresAuth: false,
              title: "Error",
              menu_type: MenuType.None,
            },
          },
          {
            path: "/loading",
            name: "nav.loading",
            component: () => import("@/components/error/Loading.vue"),
            meta: {
              requiresAuth: false,
              title: "Loading",
              menu_type: MenuType.None,
            },
          },
          {
            path: "/users/zendesk/sso",
            name: "nav.sign_in",
            component: () => import("@/components/auth/ZendeskSSO.vue"),
            meta: {
              requiresAuth: true,
              title: "Zendesk",
              menu_type: MenuType.None,
            },
          },
          {
            path: "/reports/:reportId?",
            component: () => import("@/components/reports/reports.vue"),
            meta: {
              requiresAuth: true,
              title: "nav.reports",
            },
          },
          {
            path: "/reports-domo",
            component: () => import("@/components/reports/DomoReportView.vue"),
            meta: {
              requiresAuth: true,
              title: "DomoReports",
            },
          },
          ...testRoutes,
        ],
      },
      responderRoute,
      responderReportsRoute,
      adminRoutes as RouteRecordRaw,
      {
        // special window for handling e.g. oauth callbacks in popups. sends emits event with query params and closes
        path: "/close-window",
        component: () => import("@/components/CloseWindow.vue"),
      },
      {
        path: "/docusign-redirect",
        component: () => import("@/components/auth/DocusignRedirect.vue"),
      },
      {
        path: "/:pathMatch(.*)",
        component: () => import("@/components/layouts/public.vue"),
        children: [
          {
            path: "/:pathMatch(.*)",
            component: () => import("@/components/layouts/NotFound.vue"),
            meta: {
              menu_type: MenuType.Setup,
            },
          },
        ],
      },
    ],
  });
}
